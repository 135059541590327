<template>
  <div class="offer-credit">
    <base-card-create-offer
      @addOffer="addResume"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BaseCardCreateOffer from '@/components/cards/home/BaseCardCreateOffer.vue';

export default {
  name: 'OfferCredit',
  components: {
    BaseCardCreateOffer,
  },

  data() {
    return {
      resume: [],
      offers: [],
    };
  },

  computed: {
    ...mapGetters([
      'getBusiness',
      'getNfesToOffer',
    ]),

    fullRegistration() {
      return !this.getBusiness.operational_capacity;
    },

    hasResume() {
      return this.resume.length > 0;
    },
  },

  watch: {
    getNfesToOffer(value) {
      if (value.length > 0) {
        this.$router.push({
          name: 'create-credit-offered',
          params: { offers: this.offers },
        });
      }
    },
  },

  mounted() {
    this.fetchBusinessDocuments({ business_id: this.getBusiness.id });
  },

  methods: {
    ...mapActions([
      'getMyNfes',
      'fetchNfesToOffer',
      'fetchBusinessDocuments',
      'resetNfesToOffer',
    ]),

    addResume(offers) {
      this.resetNfesToOffer();
      const types = offers.map((offer) => offer.type);

      if (types.length <= 0) return;

      const query = {
        business_id: this.getBusiness.id,
        by_status: 'able_to_offer',
        by_material_type: types.join(','),
      };

      this.fetchNfesToOffer(query);
      this.offers = offers;
    },

    resetResume() {
      this.resume = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.offer-credit {
  background: $dashboard_background;
  min-height: 100vh;
  display: flex;
  padding: 2em 2em 2em 16em;
  flex-direction: column;
}
</style>
